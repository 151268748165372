.human_agent_bulk_import {
    position: relative;

    .upload-area {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed #ccc;
        padding: 20px;
        margin: 20px 0;
        text-align: center;
        position: relative;
    }

    .upload-label {
        width: 100%;
        cursor: pointer;
        position: relative;
        z-index: 1;
    }

    .upload-box {
        padding: 20px;
        border: 2px dashed #ccc;
        background-color: #f9f9f9;
        border-radius: 5px;
    }

    .drop-zone {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        color: white;
    }

    .drop-zone-content {
        background-color: rgba(255, 255, 255, 0.9);
        padding: 40px;
        border-radius: 10px;
        text-align: center;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .drop-zone-content p {
        margin-top: 20px;
        font-size: 1.5rem;
    }

    .drop-zone input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1001;
    }

    .input_upload_container {
        position: relative;

        &:hover {
            cursor: pointer;
        }

        input[type="file"] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .progress_bulk{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        z-index: 10000;

        .progress_internal{
            max-width: 600px;
            
            text-align: center;
        }
    }
}