.reasons_for_call_component {
    .item_reason {
        border-bottom: solid 1px #e5e5e5;
        width: 100%;
        display: flex;
        padding: 0.7em 0 0.5em 0;

        .reason {
            width: 50%;
        }

        .percentage_container {
            width: 100%;
            display: flex;
            flex-direction: column;


            .bar_percentage {
                height: 1em;
                border-radius: 3px;
            }
        }
    }
}