.ranking_agents_page {
    .form-group {
        margin-bottom: 20px;
    }

    .container_orders {
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        height: fit-content;
        margin-bottom: 1em;

        .btn_order {
            cursor: pointer;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s, color 0.3s;
            height: fit-content;

            &.active {
                background-color: var(--color-main);
                color: #fff;
            }

            &:hover {
                background-color: rgba(var(--color-main-rgb),0.7);
                color: #fff;
            }

            .material-symbols-outlined {
                margin-right: 5px;
            }
        }
    }
}