.auth_layout_component {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    /* //background gradient  */
    background: linear-gradient(90deg, white 35%, var(--color-main-dark) 35%);

    .form-container {
        padding: 3em;
        width: 100%;
        padding-right: 4em;
    }

    .bg_select {
        padding-left: 6em;
        background-color: var(--color-main);
        background: linear-gradient(90deg, var(--color-main) 0%, var(--color-main-dark) 50%);
        /* border-radius: 0 5em 5em 0; */
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .logo_container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -4em;
            width: 8em;
            height: 8em;
            /* padding: 0 2em; */
            border-radius: 100%;
            background-color: white;
            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            overflow: hidden;



            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }


    @media screen and (max-width: 991px) {
        min-height: unset;

        .form_container {
            padding: 5rem 2rem;
        }

        .bg_select {
            background: linear-gradient(180deg, var(--color-main) 0%, var(--color-main-dark) 50%);
            padding-left: 1em;
            justify-content: start;
            align-items: start;

            .logo_container {
                left: 50%;
                transform: translateX(-50%);
                top: -2em;
                width: 4em;
                height: 4em;
            }
        }
    }
}