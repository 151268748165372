.agent_analysis_component {
    max-height: 67vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .analysis_card {

        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;

        .content_card {
            display: none;
            overflow-y: auto;
        }

        .title {
            font-weight: bold;
            font-size: 1.1em;
        }

        &.open {
            flex-grow: 1;
            overflow-y: auto;

            .content_card {
                margin-top: 1em;
                display: block;
            }
        }

        &:not(.open):hover {
            cursor: pointer;
            background-color: #f5f5f5;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .analysis_card.card_comments_list {

        &.open .content_card {
            overflow-y: hidden;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .comment-card {
                flex-grow: 1;
                overflow-y: auto;
                .comment-list {
                    margin-top: 1em;
                    display: flex;
                    flex-direction: column;

                    .comment-container {
                        display: flex;
                        margin-bottom: 1em;

                        &.my-comment {
                            max-width: 80%;
                            align-self: flex-end;
                            background-color: rgba(var(--color-main-rgb), 0.2);
                            border-radius: 10px;
                            padding: 10px;
                            margin-right: 1em;
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                top: calc(100% - 15px);
                                right: -10px;
                                width: 10px;
                                height: 10px;
                                background-color: rgba(var(--color-main-rgb), 0.2);
                                clip-path: polygon(0 0, 0 80%, 100% 100%);
                            }
                        }

                        &.other-comment {
                            .comment_content {
                                background-color: var(--color-gray-light);
                                border-radius: 10px;
                                padding: 10px;
                                max-width: 80%;
                                position: relative;


                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 15px;
                                    left: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background-color: var(--color-gray-light);
                                    clip-path: polygon(0 0, 100% 20%, 100% 100%);
                                }
                            }

                            .image-user {
                                width: 35px;
                                height: 35px;
                                border-radius: 100%;
                                object-fit: cover;
                                margin-right: 1em;
                            }
                        }

                        .comment_content {
                            flex-grow: 1;
                        }

                    }
                }
            }
        }
    }
}