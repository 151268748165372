.human_agent_list_page {
    .search {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input_search {
            flex-grow: 1;
            border: none;
            border: solid thin #cecece;
            border-radius: 10px 0 0 10px;
            padding: 0.5em 1em;

            &:focus {
                outline: none;
            }
        }

        .btn_search {
            border: solid thin #cecece;
            border-radius: 0 10px 10px 0;
            padding: 0.5em 1em;
            background-color: #f5f5f5;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .btn_bulk_import{
        width: fit-content;
    }
}