.accordion_component {
        .accordion_item {
            &>.accordion_item_header>* {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                padding: 0.5em 1em;
                border-radius: 10px;

                h1, h2, h3, h4, h5, h6 {
                    margin: 0;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #f5f5f5;

                    &::after {
                        color: var(--color-main);
                    }
                }

                h5 {
                    margin: 0;
                }

                &>.title {
                    flex-grow: 1;
                    margin: 0 0.5em;
                    font-size: 1.2em;
                }

                &::after {
                    content: '▼';
                    color: #cecece;
                }
            }

            // border-bottom: solid thin #cecece;

            &.opened {
                border-radius: 5px;
                padding-bottom: 0.5em;
                background-color: rgba(var(--color-main-rgb), 0.2);
                border-bottom: none;

                &>.accordion_item_header>* {
                    background-color: rgba(var(--color-main-rgb), 0.2);

                    &>.title {
                        color: var(--color-main-dark);
                    }

                    &::after {
                        color: var(--color-main-dark);
                        content: '▲';
                    }
                }

                .accordion_item_content>* {
                    display: block;
                    height: auto;
                    overflow: visible;
                    padding: 0.5em 1em;
                    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
                    opacity: 1;
                }
            }

            .accordion_item_content>* {
                display: block;
                height: 0;
                overflow: hidden;
                transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
                opacity: 0;
            }
        }
    }