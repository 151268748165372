.loading_component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;

  &.full_screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  .spinner {
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: 20px;
  }

  .spinner.large {
    width: 150px;
    height: 150px;
  }

  .logo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--color-main);
    border-radius: 100%;
    object-fit: cover;

  }

  .spinner.large .logo {
    width: 75px;
    height: 75px;
    background-color: var(--color-main);
    border-radius: 100%;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-main);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    animation-delay: -1.0s;
  }


}

@keyframes bounce {

  0%,
  100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
}