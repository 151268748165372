.layout_component {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .header {
        width: 100%;
        padding: 0.5em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        .btn_open {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title {
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 1em;
        }

        .user {
            .user_photo {
                img {
                    border-radius: 100%;
                    width: 2.7em;
                    height: 2.7em;
                    object-fit: cover;
                }
            }

            .info {
                line-height: 1.3em;
            }
        }
    }

    .layout_scroll {
        width: 100%;
        flex-grow: 1;
        overflow: hidden;
        display: flex;

        .layout_sidebar_component {
            .content_sidebar {

                max-width: 5em;
                height: 100%;
                padding: 1em;
                float: left;
                overflow: hidden;
                overflow-y: auto;
                background: linear-gradient(130deg, var(--color-main-dark) 10%, var(--color-main) 50%, var(--color-main-dark) 100%);
                display: flex;
                flex-direction: column;
                color: white;

                .img_icon {
                    width: 3em;
                    height: 3em;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background-color: white;
                    margin-bottom: 1em;

                    .open {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .sidebar_option {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5em 1em;
                    border-radius: 10px;
                    margin-bottom: 1em;


                    .sidebar_option_icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }

                    &.active {
                        background-color: var(--color-main-bg);
                        border-radius: 30px;
                    }

                    .sidebar_option_text {
                        display: none;
                    }
                }
            }
        }

        .layout_content {
            overflow-y: auto;
            flex-grow: 1;
            padding: 1em;
            background-color: #efefef;
        }

    }

    &.open {

        .layout_sidebar_component {
            .content_sidebar {
                width: fit-content;
                max-width: 300px;
                transition: max-width 0.5s;

                .img_icon {
                    width: 100%;
                    border-radius: 0;
                    background-color: transparent;

                    .open {
                        display: block;
                    }

                    .closed {
                        display: none;
                    }
                }

                .sidebar_option {

                    .sidebar_option_text {
                        display: block;
                        padding-left: 1em;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .layout_scroll {
            .layout_sidebar_component {
                .content_sidebar {
                    display: none;
                }
            }
        }

        &.open {
            .layout_scroll {
                .layout_sidebar_component {
                    .content_sidebar {
                        display: flex;
                        position: fixed;
                        top: 0;
                        left: 0;
                        height: 100%;
                        z-index: 1000;
                    }

                    .bg_drop {
                        display: block;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        animation: fadeIn 0.5s;
                    }
                }
            }
        }
    }
}