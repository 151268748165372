.card_loader_component {
    .loader_container_card {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner {
            margin: 10px;
            opacity: 0.3;
        }
    }
}