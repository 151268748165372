.delete_modal_component {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .bg_modal {
        background: #3d3d3d70;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .modal_container {
        position: fixed;
        width: 400px;
        height: auto;
        z-index: 1001;
        background-color: white;
        border-radius: 6px;
        top: calc(50% - 200px);
        left: calc(50% - 200px);
        box-shadow: 0 1px 2px #707070;
        padding: 10px;

        .delete_modal_component_header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(216, 216, 216);
            font-weight: 600;
            padding: 10px;
            color: var(--color-danger);

            .delete_modal_component_header_icon {
                stroke-width: 1px;
                stroke: rgb(87, 87, 87);
                cursor: pointer;
            }
        }

        .delete_modal_message {
            font-size: 0.8em;
            background-color: #b31e1e91;
            color: white;
            padding: 10px;
        }

        .delete_modal_component_content {
            padding: 10px;
        }

        .delete_modal_component_content_input {
            width: calc(100% - 1em);
            border: 1px solid var(--color-danger);
            border-radius: 4px;
            padding: 5px;
            margin-left: 0.5em;
            margin-right: 0.5em;
            margin-bottom: 10px;
        }

        .error_input {
            color: #bf1650;

            &::before {
                display: inline;
                content: "⚠ ";
            }
        }
    }

    .delete_modal_component_footer {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5em;

        button {
            width: 48%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}