.table_satisfaction_component {
    .sentiment_conent {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 5px;
        margin: 5px;
        color: white;

        span {
            margin-right: 0.2em;
        }
    }
}