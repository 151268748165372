.target_circle_component {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .target_info{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1{
      color: var(--color-main);
    }

    .service_target{
      font-size: 0.8em;
    }

    .increment_container{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-main-bg);
      color: white;
      border-radius: 5px;
      padding: 0.3em;
      margin-top: 1em;

      &.increment {
        background-color: var(--color-main);
      }

      &.decrement {
        background-color: var(--color-secondary);
      }
    }
  }
}