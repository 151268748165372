.auth_info_component {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    
    .logo {
        height: 200px;
        margin: 0 auto;
    }
}