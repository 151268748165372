.not_results_component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .icon {
        font-size: 4rem;
        color: #ccc;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 1.5rem;
        color: #333;
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 1rem;
        color: #666;
    }
}