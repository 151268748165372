.result_ia_audio_component {
    max-height: 67vh;
    overflow-y: auto;
    .chat {
        padding: 0.5em;
        border-radius: 10px;
        background-color: #efefef;
        margin-bottom: 10px;
        margin-right: 3em;

        &.mine {
            background-color: var(--color-main-bg);
            margin-left: 3em;
            margin-right: 0;
            text-align: end;
        }
}

    .category_result {

        .question {
            margin-bottom: 10px;
            margin-left: 3em;
            text-align: end;
            background-color: var(--color-main-bg);
            padding: 0.8em 1em;
            border-radius: 10px;
        }

        .response {
            margin-bottom: 20px;
            margin-right: 3em;
            background-color: #efefef;
            padding: 0.8em 1em;
            border-radius: 10px;
        }

        .category_description {
            margin: 1em 0;
            background-color: white;
            border-radius: 10px;
            padding: 0.8em 1em;
        }
    }
}