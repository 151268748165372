.error-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;

    h1 {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    a {
        font-size: 1.2rem;
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}