.button_component {
    display: flex;
    align-items: center;

    &>.icon {
        margin-right: 0.5em;
        display: flex;
        align-items: center;
    }

    &.primary {
        // background: linear-gradient(90deg, var(--color-main) 0%, var(--color-main-dark) 50%);
        background-color: var(--color-main);
        color: white;

        &:hover {
            background-color: var(--color-main);
            background: linear-gradient(90deg, var(--color-main) 0%, var(--color-main-dark) 50%);
            cursor: pointer;
            // color: var(--color-secondary);
            color: white;
        }
    }

    &.primaryOutline{
        background-color: white;
        color: var(--color-main);
        border: 1px solid var(--color-main);

        &:hover {
            background-color: var(--color-main);
            color: white;
            cursor: pointer;
        }
    }

    &.outstanding {
        background-color: var(--color-main-dark);
        color: white;
        font-weight: 600;
        background: linear-gradient(90deg, var(--color-main-dark) 0%, var(--color-main) 50%, var(--color-main-dark) 100%);

        &:hover {
            background-color: var(--color-main-dark);
            background: linear-gradient(90deg, var(--color-main-dark) 0%, var(--color-main) 50%, var(--color-main-dark) 100%);
            cursor: pointer;
            color: yellow;
        }
    }

    &.danger {
        background-color: var(--color-danger);
        color: white;

        &:hover {
            background-color: var(--color-danger);
            background: linear-gradient(90deg, var(--color-danger) 0%, var(--color-danger-dark) 50%);
            cursor: pointer;
            color: white;
        }
    }

    &.dangerOutline {
        background-color: white;
        color: var(--color-danger);
        border: 1px solid var(--color-danger);

        &:hover {
            background-color: var(--color-danger);
            color: white;
            cursor: pointer;
        }
    }
}