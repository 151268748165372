.audio_zone_component {

    .drop_zone {
        width: 100%;
        height: 400px;
        border: 2px dashed #ccc;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &>input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }

        &>.dragging {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgb(156, 189, 224);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 3em;
            color: var(--color-main-dark);

            .pulse {
                animation: pulse 1s infinite;

                @keyframes pulse {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.1);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                span {
                    font-size: 1em;
                }
            }
        }

        .loader {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

        }

        &>.description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }
}