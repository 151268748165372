:root {
  --color-main: #6b8cb0;
  --color-main-rgb: 107, 140, 176;
  --color-main-bg: #6b8cb054;
  --color-main-dark: #1b2449;
  --color-secondary: #debf5e;
  --color-danger: #bf1650;
  --color-danger-dark: #8f0f3e;
  --color-gray-light: #f5f5f5;
}

.bg_white {
  background-color: white;
}

.card {
  border: none;

  &.elevated {
    box-shadow: 0 0 10px 0px #cececece;
  }
}


form {
  .form-group.error {


    input,
    textarea,
    select {
      &::placeholder {
        color: #bf1650;
      }

      color: #bf1650;
      border: solid thin #bf1650;
      background-color: #bf165055;
    }

    &.error_group {
      .PhoneInput {

        .PhoneInputCountry::placeholder {
          color: #bf1650;
        }

        color: #bf1650;
        border: solid thin #bf1650;
        background-color: #bf165055;
      }
    }

    aside {
      color: #bf1650;

      &::before {
        display: inline;
        content: "⚠ ";
      }
    }

    label {
      color: #bf1650;
    }
  }

  aside {
    color: #bf1650;
  }

  .password_input_wrapper {
    position: relative;

    .visibility_icon {
      position: absolute;
      top: 50%;
      right: 0.5em;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.text {
  &_color_primary {
    color: var(--color-main);
  }

  &_color_primary_dark {
    color: var(--color-main-dark);
  }

  &_color_secondary {
    color: var(--color-secondary);
  }

  &_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_bold {
    font-weight: bold;
  }

  &_light {
    font-weight: lighter;
    font-size: 0.9em;
  }
}

.hover:hover {
  cursor: pointer;
}

.floating_button {
  position: fixed;
  bottom: 1em;
  right: 3em;
  z-index: 1000;
  box-shadow: 3px 5px 5px 0px #00000055;
  border-radius: 10px;
}

.floating_buttons{
  position: fixed;
  bottom: 1em;
  right: 3em;
  z-index: 1000;

  display: flex;

  .btn {
    box-shadow: 3px 5px 5px 0px #00000055;
    border-radius: 10px;  
  }
}

@media screen and (min-width: 576px) and (max-width: 1200px) {
  .container {
    max-width: unset;
  }
}

.container_buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 48%;
    justify-content: center;
  }
}

.modal_body {
  max-height: 67vh;
  // overflow-y: auto;
}

.chip {
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0.5em;
  border-radius: 10px;
  background-color: var(--color-main-bg);
  color: var(--color-main);
}

.circle_button {
  border-radius: 100%;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  &.btn>.icon {
    margin: 0 0 0 0 ;
    margin-right: 0;
  }
}