.dashboard_page {
    .card_feeling_component {
        .card_content_feeling {
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            h5{
                font-size: 2em;
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-bottom: 0;

                span {
                    margin-left: 0.2em;
                }
            }
        }
    }
}